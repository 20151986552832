import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import {injectIntl} from "gatsby-plugin-react-intl";
import { Container } from "react-bootstrap"
import Pulse from 'react-reveal/Pulse';

const LastBlogPostBanner = props => (

    <StaticQuery
        query={graphql`
          query {
            allMdx(
                  sort: { fields: [fields___publishDate], order: DESC }
                  limit: 1
                  filter: {
                    frontmatter: { pageType: { eq: "blog" } }
                    fields: { published: { eq: true } }
                  }
                ) {
              edges {
                node {
                  fields {
                    slug
                    publishDate
                    cover
                  }
                  frontmatter {
                    title
                    cover
                  }
                }
              }
            }
          }
        `}
        render={data => {
            let post = data.allMdx.edges[0];
            return (
              <>
                <a className={`blog-banner-area`} href={post.node.fields.slug}>
                  <div className={`blog-banner-badge`}>{props.content.badge}</div>
                  <div className={`blog-banner-text`}><span>{props.content.text}</span> {post.node.frontmatter.title}</div>
                </a>
              </>
            )
        }}
    />
);

injectIntl(LastBlogPostBanner);


class BlogBanner extends React.Component {

    render() {
        return (
          <div className={`blog-banner`}>
            <Container>
              <Pulse>
                <LastBlogPostBanner content={{badge: this.props.intl.formatMessage({id: "shared.new"}), text:this.props.intl.formatMessage({id: "shared.fromOurBlog"})}} />
              </Pulse>
            </Container>
          </div>
        )
    }
}

export default injectIntl(BlogBanner)
