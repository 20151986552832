import React from "react"
import Loadable from "@loadable/component"
import { injectIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/heroes/hero"
import ClientsTicker from "../components/misc/clients-ticker";

import HeroData from "../content/home/hero.json"
import TickerData from "../content/home/ticker.json"
import BlogBanner from "../components/misc/blog-banner"

const Intro = Loadable(() => import("../components/home/intro"))
const FeaturesChannels = Loadable(() => import("../components/home/channels"))
const Testimonials = Loadable(() => import("../components/home/testimonials"))
const FeaturesHighlights = Loadable(() => import("../components/home/highlights"))
const StaticBlock = Loadable(() => import("../components/home/static-block"))
const HomeResources = Loadable(() => import("../components/home/resources"))
const Cases = Loadable(() => import("../components/home/cases"))
const MailingList = Loadable(() => import("../components/ctas/mailing-list"))
const DemoTrial = Loadable(() => import("../components/ctas/demo-trial"))


const IndexPage = ({ intl }) => {

  return (
    <Layout>
      <Seo lang={intl.locale}
           title={intl.formatMessage({ id: "pages.home.title" })}
           description={intl.formatMessage({ id: "pages.home.description" })}
      />
      <BlogBanner />
      <Hero
        theme={`primary`}
        content={HeroData[intl.locale]}
      />
      <ClientsTicker content={TickerData[intl.locale]} />
      <Intro />
      <FeaturesChannels />
      <Testimonials />
      <FeaturesHighlights />
      <StaticBlock />
      <Cases />
      <HomeResources />
      <MailingList />
      <DemoTrial />
    </Layout>

  )

}

export default injectIntl(IndexPage)
